import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Helpers } from '../helpers';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { environment } from '../../environments/environment';

@Component({
  selector: '.app-inner-pages',
  templateUrl: './inner-pages.component.html'
})
export class InnerPagesComponent implements OnInit {

    env = environment;
  constructor(private _router: Router, private _script: ScriptLoaderService) {

    // this.router.events.subscribe((val) => {
    //   Helpers.setLoading(val instanceof NavigationStart);
    // });
    // this.router.events.subscribe((val1) => {
    //   Helpers.unSetLoading(val1 instanceof NavigationEnd);
    // });

  }
  ngOnInit() {
    // this._script.loadScripts('body', ['assets/js/vendors.bundle.js'], true);
    // this._router.events.subscribe((route) => {
    //   if (route instanceof NavigationStart) {
    //     Helpers.setLoading(true);
    //   }
    //   if (route instanceof NavigationEnd) {
    //     // init required js
    //     Helpers.setLoading(false);
    //     // content m-wrapper animation
    //     console.log("second");
    //     $.getScript('assets/js/functions.js');
    //     $.getScript('assets/js/plugins.js');
    //   }
    // });
  }
  ngAfterViewInit() {
    // $('.css3-spinner').css("display", "none");
  }


}
