// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,  
   apiURL: 'https://dqs-nxt.com/dqs-nxt/v1',
  //apiURL: 'http://localhost:8000',
  baseURL: '',
  razorpayKeyId: 'rzp_test_D7b5SEFkq6LT32',
  // razorpayKeyId: 'rzp_live_u8qfeTTmoBiHlQ'
};

//https://dqs-nxt.com/dqs-nxt/v1
//http://209.172.36.244
//'http://14.141.46.113/dqs-nxt/v1'
//'http://localhost:3000'
//rzp_live_u8qfeTTmoBiHlQ
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
