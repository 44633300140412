import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable } from 'rxjs/Observable';
import "rxjs/add/operator/map";
import { environment } from '../../environments/environment';

declare let document: any;
declare let mApp: any;
interface Script {
    src: string;
    loaded: boolean;
}

@Injectable()
export class ScriptLoaderService {
    public _scripts: Script[] = [];
    env = environment;
    url = this.env.apiURL;
    baseurl = this.env.baseURL;
    constructor(private http: HttpClient) {
    }
    /**
     * @deprecated
     * @param tag
     * @param {string} scripts
     * @returns {Promise<any[]>}
     */
    load(tag, ...scripts: string[]) {
        scripts.forEach((src: string) => {
            if (!this._scripts[src]) {
                this._scripts[src] = { src: src, loaded: false };
            }
        });

        let promises: any[] = [];
        scripts.forEach((src) => promises.push(this.loadScript(tag, src)));
        return Promise.all(promises);
    }

    /**
     * Lazy load list of scripts
     * @param tag
     * @param scripts
     * @param loadOnce
     * @returns {Promise<any[]>}
     */
    loadScripts(tag, scripts, loadOnce?: boolean) {
        loadOnce = loadOnce || false;

        scripts.forEach((script: string) => {
            if (!this._scripts[script]) {
                this._scripts[script] = { src: script, loaded: false };
            }
        });

        let promises: any[] = [];
        scripts.forEach(
            (script) => promises.push(this.loadScript(tag, script, loadOnce)));

        return Promise.all(promises);
    }

    /**
     * Lazy load a single script
     * @param tag
     * @param {string} src
     * @param loadOnce
     * @returns {Promise<any>}
     */
    loadScript(tag, src: string, loadOnce?: boolean) {
        loadOnce = loadOnce || false;

        if (!this._scripts[src]) {
            this._scripts[src] = { src: src, loaded: false };
        }

        return new Promise((resolve, reject) => {
            // resolve if already loaded
            if (this._scripts[src].loaded && loadOnce) {
                resolve({ src: src, loaded: true });
            }
            else {
                // load script tag
                let scriptTag = $('<script/>').
                    attr('type', 'text/javascript').
                    attr('src', this._scripts[src].src);

                $(tag).append(scriptTag);

                this._scripts[src] = { src: src, loaded: true };
                resolve({ src: src, loaded: true });
            }
        });
    }

    /* Call methods Without page Refreshing Header,footer,sidebar.
  Block UI load While Calling Backend Methods
  */
    blockUI(loadUI?: boolean) {
        loadUI = loadUI || false;
        if (loadUI) {
            mApp.blockPage({
                overlayColor: "#000000",
                type: "loader",
                state: "brand",
                message: "Please wait..."
            })
        } else {
            mApp.unblockPage()
        }
    }

    getData(link) {
        return this.http.get(this.url + link, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' as 'response' });
    }

    getData1(link): Observable<any> {
        return this.http.get(this.url + link, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Auth': localStorage.getItem('X-Auth') }) })
            .map((response: HttpResponse<any>) => response,
                (error: HttpErrorResponse) => error);
    }

    saveData(link, data) {
        return this.http.post(this.url + link, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' as 'response' });
    }

    postData(link, data): Observable<any> {
        return this.http.post(this.url + link, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Auth': localStorage.getItem('X-Auth') }) })
            .map((response: HttpResponse<any>) => response,
                (error: HttpErrorResponse) => error);
    }

    deleteData(link) {
        return this.http.delete(this.url + link, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' as 'response' });
    }

    getApiUrl() {
        return this.url;
    }

    getBaseUrl() {
        return this.baseurl;
    }
}