import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InnerPagesComponent } from './inner-pages.component';
const routes: Routes = [
    {
        path: '',
        component: InnerPagesComponent,
        // canActivate: [AuthenticationGuard],
        // runGuardsAndResolvers: 'always',
        children: [
            {
                'path': 'home.html',
                'loadChildren': '.\/pages\/home\/home.module#HomeModule',
                'data': { title: 'DQS Nxt: Audit Management, Doc Management, Supplier Audit Management, Training and Custom Certifications in India',
                 metaTags : { name: 'description',
                 content: 'With DQS Nxt-Gen services and solutions, experience a better way to manage multiple audits, organisational docs, corporate training and custom certifications' } }
            },

            {
                'path': 'dqs-nxt-audit-organiser.html',
                'loadChildren': '.\/pages\/audit-manager\/audit-manager.module#AuditManagerModule',
                'data': { title: 'DQS-Nxt - Audit Organiser',
                metaTags : { name: 'description',
                content: 'A better way to manage your audits is here! Transform isolated, manual processes, spreadsheets and point solutions into an automated audit management solution' } }
            },
            {
                'path': 'dqs-nxt-doc-organiser.html',
                'loadChildren': '.\/pages\/doc-organizer\/doc-organizer.module#DocOrganizerModule',
                'data': { title: 'DQS-Nxt - Doc Organiser',
                metaTags : { name: 'description',
                content: 'Digitize organizational documented Information, get better control over sensitive documents and access documents from virtually anywhere- 24X7 ' } }
            },
            {
                'path': 'dqs-nxt-supplier-audit-management.html',
                'loadChildren': '.\/pages\/supplier-assessment\/supplier-assessment.module#SupplierAssessmentModule',
                'data': { title: 'DQS-Nxt - Supplier Audit Management',
                metaTags : { name: 'description',
                content: 'Manage the whole array of activities, processes and data related to supplier audits in a single application which minimises errors and reduces the cost' } }
            },
            {
                'path': 'dqs-nxt-managed-training-solution.html',
                'loadChildren': '.\/pages\/mts\/mts.module#MtsModule',
                'data': { title: 'DQS-Nxt - Managed Training Solution',
                metaTags : { name: 'description',
                content: 'A cost effective end-to-end, fully coordinated, vendor-neutral, outsourced Learning and Development Administration and Management solution.'}}
            },
            {
                'path': 'dqs-nxt-global-conformance-mark.html',
                'loadChildren': '.\/pages\/gc-mark\/gc-mark.module#GcMarkModule',
                'data': { title: 'DQS-Nxt - Global Conformance Mark',
                metaTags : { name: 'description',
                content: 'Globally recognised certification and labelling program designed to help organisations stand out in a crowded market and build a strong brand equity' }}
            },
            {
                'path': 'dqs-nxt-product-inspection-validation.html',
                'loadChildren': '.\/pages\/product-inspection\/product-inspection.module#ProductInspectionModule',
                'data': { title: 'DQS-Nxt - Product Inspection & Validation',
                metaTags : { name: 'description',
                content: 'Optimize and build value in Product inspection, field inspection, quantity control or product validation' }}
            },
            {
                'path': 'coming-soon.html',
                'loadChildren': '.\/pages\/coming-soon\/coming-soon.module#ComingsoonModule',
                'data': { title: 'DQS-Nxt - Coming Soon',
                metaTags : { name: 'description',
                content: 'Find out what\'s new, opinions, analysis, industry trends and much more at DQS Nxt' }}
            },
            {
                'path': 'contactus.html',
                'loadChildren': '.\/pages\/contactus\/contactus.module#ContactusModule',
                'data': { title: 'DQS-Nxt - Contact Us',
                 metaTags : { name: 'description',
                 content: 'Reach us for any enquires, information, feedback, schedule a demo and much more' }}
            },
            {
                'path': 'referral.html',
                'loadChildren': '.\/pages\/referral\/referral.module#ReferralModule',
                'data': { title: 'DQS-Nxt - Referral',
                 metaTags : { name: 'description',
                 content: 'DQS-Nxt Referral Program' }}
            },
            {
                'path': 'faq.html',
                'loadChildren': '.\/pages\/faq\/faq.module#FaqModule',
                'data': { title: 'DQS-Nxt - FAQ',
                metaTags : { name: 'description',
                content: 'Find out answers to the most Frequently Asked Questions related to our services and solutions' }}
            },
            {
                'path': 'termsandcondition.html',
                'loadChildren': '.\/pages\/terms-and-condition\/terms-and-condition.module#TermsAndConditionModule',
                'data': { title: 'DQS-Nxt - Terms and Condition',
                metaTags : { name: 'description', content: 'Terms & Condition' }}
            },
            {
                'path': 'privacypolicy.html',
                'loadChildren': '.\/pages\/privacy-policy\/privacy-policy.module#PrivacyPolicyModule',
                'data': { title: 'DQS-Nxt - Privacy Policy',
                metaTags : { name: 'description', content: 'Privacy Policy' }}
            },
            {
                'path': 'login.html',
                'loadChildren': '.\/pages\/login\/login.module#LoginModule',
                'data': { title: 'DQS-Nxt - login',
                metaTags : { name: 'description', content: 'DQS-Nxt Login' }}
            },
            {
                'path': 'verifyemail.html/:email/:code',
                'loadChildren': '.\/pages\/login\/verify.module#VerifyModule',
                'data': { title: 'DQS-Nxt - Email Validation',
                metaTags : { name: 'description', content: 'Email Validation' }}
            },
            {
                'path': 'best-practices.html',
                'loadChildren': '.\/pages\/best-practices\/best-practices.module#BestPracticesModule',
                'data': { title: 'DQS-Nxt - Best Practices',
                metaTags : { name: 'description', content: 'Best Practices' } }
            },
            {
                'path': 'products.html',
                'loadChildren': '.\/pages\/profile\/profile-home\/profile-home.module#ProfileHomeModule',
                'data': { title: 'DQS-Nxt - Profile',
                metaTags : { name: 'description', content: 'Profile' }}
            },
            {
                'path': 'profileinfo.html',
                'loadChildren': '.\/pages\/profile\/profile-detail\/profile-detail.module#ProfileDetailModule',
                'data': { title: 'DQS-Nxt - Profile Information',
                metaTags : { name: 'description', content: 'Profile Information' } }
            },
            {
                'path': 'supportdashboard.html',
                'loadChildren': '.\/pages\/profile\/support-dashboard\/support-dashboard.module#SupportDashboardModule',
                'data': { title: 'DQS-Nxt - Support Dashboard',
                metaTags : { name: 'description', content: 'Support Dashboard' } }
            },
            {
                'path': 'profileproductdetail.html/:id',
                'loadChildren': '.\/pages\/profile\/product-detail\/product-detail.module#ProductDetailModule',
                'data': { title: 'DQS-Nxt - Productdetail',
                metaTags : { name: 'description', content: 'Product Details' }}
            },
            {
                'path': 'profileproductdetailpurchase.html/:id',
                'loadChildren': '.\/pages\/profile\/product-purchase\/product-purchase.module#ProductPurchaseModule',
                'data': { title: 'DQS-Nxt - Product Purchase',
                metaTags : { name: 'description', content: 'Product Purchase' } }
            },
            {
                'path': 'product-checkout.html',
                'loadChildren': '.\/pages\/profile\/product-checkout\/product-checkout.module#ProductCheckoutModule',
                'data': { title: 'DQS-Nxt - Product Checkout',
                metaTags : { name: 'description', content: 'Product Checkout' } }
            },
            {
                'path': 'payment.html',
                'loadChildren': '.\/pages\/profile\/payment\/payment.module#PaymentModule',
                'data': { title: 'DQS-Nxt - Payment',
                metaTags : { name: 'description', content: 'Payment' }}
            },
            {
                'path': 'purchase-completed.html',
                'loadChildren': '.\/pages\/profile\/product-purchase-completed\/product-purchase-completed.module#ProductPurchaseCompletedModule',
                'data': { title: 'DQS-Nxt - Purchase Completed',
                metaTags : { name: 'description', content: 'Purchase Completed' }}
            },
            {
                'path': 'not-found.html',
                'loadChildren': '.\/pages\/not-found\/not-found.module#NotFoundModule',
                'data': { title: 'DQS-Nxt - Not Found!!!',
                metaTags : { name: 'description', content: 'Not Found' } }
            },
            {
                'path': '',
                'loadChildren': '.\/pages\/home\/home.module#HomeModule',
                'data': { title: 'DQS-Nxt - Home',
                metaTags : { name: 'description', content: 'Home' } }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InnerPagesRoutingModule {
}
