
import { Component, OnInit } from '@angular/core';
import { ScriptLoaderService } from './_services/script-loader.service';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Helpers } from './helpers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private _script: ScriptLoaderService) { }

  ngOnInit() {
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationStart) {
        Helpers.setLoading(true);
      }
      if (route instanceof NavigationEnd) {
        // init required js
        Helpers.setLoading(false);
        this._script.loadScripts('app-root',
          [
            'assets/js/plugins.js',
            'assets/bootstrap-select/dist/js/bootstrap-select.js',
            'assets/js/master-theme-aid.js',
            'assets/js/functions.js'
          ]);
      }
    });
  }

}
