import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
// import { AvatarModule } from 'ng2-avatar';
import { InnerPagesRoutingModule } from './inner-pages/inner-pages-routing.module';
import { NotFoundComponent } from './inner-pages/pages/not-found/not-found.component';
import { ScriptLoaderService } from './_services/script-loader.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { InnerPagesComponent } from './inner-pages/inner-pages.component';
import { TitleService } from './_services/title.service';
import { WindowRef } from './_services/window-ref.service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
@NgModule({
  declarations: [
    AppComponent,
    InnerPagesComponent,
    NotFoundComponent,
  ],
  exports: [
    // InnerPagesComponent
    // AvatarModule
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    InnerPagesRoutingModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule
    // AvatarModule.forRoot()
    // InnerPagesModule
  ],
  providers: [ScriptLoaderService, TitleService,WindowRef],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(titleService: TitleService) {
    titleService.init();
  }
}
